@use '../../../../src/scss/globals/' as g;
@keyframes loop {
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(-100%);
  }
}
.home-concept {
  position: relative;
  z-index: 10;
  &__bgText {
    color: #eff5e1;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    animation: loop 40s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding-right: 1.5em;
    &__02 {
      animation: loop 40s -20s linear infinite;
    }
    &__wrapper {
      position: absolute;
      margin: auto;
      right: 0;
      left: 0;
    }
  }
  &__inner {
    position: relative;
    z-index: 2;
  }
  @media screen and (min-width: 768px) {
    &__bgText {
      font-size: 200px;
      &__wrapper {
        top: 250px;
        bottom: 0;
      }
    }
    &__inner {
      width: 1440px;
      margin-right: auto;
      margin-left: auto;
    }
    &__textInner {
      width: 485px;
      margin-right: auto;
      margin-left: auto;
    }
    &__subText {
      font-size: 26px;
      font-weight: 700;
      line-height: 1.6;
      margin: 40px 0 80px;
    }
    &__text {
      font-size: 16px;
      line-height: 2;
      margin-bottom: 65px;
    }
    &__img {
      &__01 {
        position: absolute;
        top: -235px;
        left: 0;
      }
      &__02 {
        position: absolute;
        top: -162px;
        right: 0;
      }
      &__03 {
        position: absolute;
        bottom: -185px;
        right: 0;
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    &__bgText {
      font-size: g.vw(50);
      &__wrapper {
        bottom: g.vw(500);
      }
    }
    &__inner {
      width: 100%;
      padding-right: g.vw(10);
      padding-left: g.vw(10);
    }
    &__textInner {
      width: 100%;
      padding: 0 23vw g.vw(30) 27vw;
    }
    &__img {
      &__01 {
        position: absolute;
        width: 24vw;
        top: g.vw(-60);
        left: g.vw(10);
        max-width: g.vw(160);
        img {
          width: 100%;
        }
      }
      &__02 {
        width: 22vw;
        position: absolute;
        top: g.vw(-20);
        right: g.vw(10);
        max-width: g.vw(160);
      }
      &__03 {
        width: 22vw;
        bottom: g.vw(0);
        right: g.vw(10);
        max-width: g.vw(130);
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    &__bgText {
      font-size: g.vw(70);
      top: g.vw(50);
      &__wrapper {
        top: initial;
        bottom: 55vw;
      }
    }
    &__inner {
      width: 100%;
      padding-right: g.vw(10);
      padding-left: g.vw(10);
    }
    &__textInner {
      width: 100%;
      padding: g.vw(150) 0 g.vw(60);
    }
    &__img {
      &__01 {
        position: absolute;
        top: g.vw(-100);
        left: g.vw(20);
        max-width: g.vw(160);
      }
      &__02 {
        position: absolute;
        top: g.vw(-40);
        right: g.vw(20);
        max-width: g.vw(160);
      }
      &__03 {
        width: g.vw(100);
        bottom: g.vw(-50);
        right: g.vw(20);
        max-width: g.vw(130);
      }
    }
  }
  @media screen and (max-width: 767px) {
    position: relative;
    z-index: 10;
    margin-top: g.vw(-130);
    padding-bottom: g.vw(50);
    &__bgText {
      font-size: g.vw(70);
      top: g.vw(330);
      &__wrapper {
        top: initial;
        bottom: 155vw;
      }
    }
    &__img {
      &__01 {
        margin-bottom: g.vw(40);
        padding-right: g.vw(24);
      }
      &__02 {
        margin-top: g.vw(50);
        margin-left: g.vw(24);
      }
      &__03 {
        display: none;
      }
    }
    &__textInner {
      padding: 0 g.vw(24);
    }
    &__subText {
      font-size: 1.7rem;
      font-weight: 700;
      line-height: 1.6;
      margin-bottom: g.vw(24);
    }
    &__text {
      font-size: 1.5rem;
      line-height: 2;
      margin-bottom: g.vw(35);
    }
  }
}