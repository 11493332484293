@use '../../../../scss/globals/' as g;

.div {
  color: var(--color-blue);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  &::before {
    content: '●　';
  }
  @media screen and (min-width: 768px) {
    font-size: 11px;
  }
  @media screen and (max-width: 767px) {
    font-size: g.vw(11);
  }
}