@use '@globals' as g;

.home-reason {
  @media screen and (min-width: 768px) {
    background: url('/images/bg_03.png') no-repeat top center / 2000px;
    margin-top: 155px;
    &__inner {
      position: relative;
      width: 1440px;
      margin-right: auto;
      margin-left: auto;
      padding-top: 155px;
      padding-left: 140px;
    }
    &__box {
      width: 1440px;
      margin-right: auto;
      margin-left: auto;
      &__item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 145px;
        &.point02 {
          align-items: start;
        }
        &:nth-of-type(even) {
          flex-direction: row-reverse;
          padding-right: 140px;
        }
        &:nth-of-type(odd) {
          padding-left: 140px;
        }
        &__text {
          width: 610px;
          &__no {
            color: var(--color-green);
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: 600;
            span {
              font-size: 18px;
              margin-left: 0.25em;
            }
          }
          &__title {
            font-size: 26px;
            font-weight: 700;
            margin: 17px 0 40px;
          }
        }
        &__img {
          width: 686px;
        }
        &:nth-of-type(even) &__text {
          width: 540px;
        }
        &:nth-of-type(odd) &__img {
          text-align: right;
        }
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    background-size: 1399px;
    &__inner {
      width: 100%;
      padding-top: g.vw(30);
      padding-right: g.vw(20);
      padding-left: g.vw(20);
    }
    &__box {
      width: 100%;
      padding-right: g.vw(10);
      padding-left: g.vw(10);
      &__item {
        padding: 0 !important;
        &__text {
          width: 62%!important;
        }
        &__img {
          width: 33%!important;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-size: 1024px;
    &__inner {
      width: 100%;
      padding-top: g.vw(30);
      padding-right: g.vw(20);
      padding-left: g.vw(20);
    }
    &__box {
      width: 100%;
      padding-right: g.vw(10);
      padding-left: g.vw(10);
      &__item {
        padding: 0 !important;
        &__text {
          width: 62%!important;
        }
        &__img {
          width: 33%!important;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    background: url('/images/bg_03_sp.png') no-repeat top center / 100%;
    margin-top: g.vw(50);
    padding: g.vw(70) g.vw(24) 0;
    &__box {
      margin-top: g.vw(80);
      &__item {
        & + & {
          margin-top: g.vw(55);
        }
        &__text {
          &__no {
            color: var(--color-green);
            font-family: "Montserrat", sans-serif;
            font-size: 1.2rem;
            font-weight: 600;
            span {
              font-size: 1.8rem;
              margin-left: 0.25em;
            }
          }
          &__title {
            font-size: 1.7rem;
            font-weight: 700;
            margin: 8px 0 24px;
          }
          &__p {
            font-size: 1.5rem;
          }
        }
        &__img {
          margin-top: 45px;
        }
        &:nth-of-type(1) &__img {
          width: calc(100% + g.vw(24));
          margin-right: g.vw(-24);
          img {
            border-radius: g.vw(15) 0 0 g.vw(15);
          }
        }
        &:nth-of-type(2) &__img {
          width: calc(100% + g.vw(24));
          margin-left: g.vw(-24);
        }
        &:nth-of-type(3) &__img {
          width: calc(100% + g.vw(24));
          margin-right: g.vw(-24);
        }
      }
    }
  }
}
.title02 {
  &__wrapper {
    color: var(--color-white);
  }
  @media screen and (min-width: 768px) {
    &__en {
      font-family: "Montserrat", sans-serif;
      font-size: 11px;
      font-weight: 700;
      &::before {
        content: '●　';
      }
    }
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    &__en {
      font-family: "Montserrat", sans-serif;
      font-size: g.vw(11);
      font-weight: 700;
      &::before {
        content: '●　';
      }
    }
    font-size: g.vw(17);
    font-weight: 700;
  }
}