@use '../../../../scss/globals/' as g;

.h1,.h2,.h3,.h4,.h5,.h6,.div {
  font-weight: 700;
}
.h1 {
  font-size: 80px;
  text-align: center;
}
.h2 {
  font-weight: 700;
  @media screen and (min-width: 768px) {
    font-size: 45px;
    margin-bottom: 20px;
    span {
      font-size: 35px;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 30px;
    margin-bottom: 35px;
    line-height: 1.6;
    span {
      font-size: 25px;
    }
  }
}
.h3 {
  font-size: 3rem;
  margin-bottom: g.$marginVw__XS;
}
.h4 {
  font-size: 24px;
}
.h5 {
  font-size: 18px;
}
.h6 {
  font-size: 16px;
}
.div {
  font-size: g.$default_fz;
}