@use '@globals' as g;
.responsive-link-button {
  display: inline-block;
  
  & a {
    background: var(--color-blue) url('/svg/icon_arrow.svg') no-repeat right 30px top 50% / 35px 10px;
    border-radius: 50px;
    display: block;
    text-align: center;
    transition: all .2s ease-out;
    line-height: 1.6;
    font-size: 1.4rem;

    &:hover {
      opacity: 0.6;
    }
    @media screen and (min-width: 768px) {
      padding: 20px 60px 20px 40px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1399px) {
      background-position: right 15px top 50%;;
      font-size: g.vw(5.5);
      padding: 1vw 25px 1vw 10px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 1.5vw 25px 1.5vw 10px;
    }
    @media screen and (max-width: 767px) {
      padding: 10px g.vw(10);
    }
    @media screen and (min-width: 541px) and (max-width: 790px) {
      background-image: none;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }
    @include g.media('lg') {
      background-position: right 20px top 50%;;
    }
  }
};