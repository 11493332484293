@use "@globals" as g;

.home-sezyutsu-menu-list {

  &__menuList {
    display: flex;
      flex-wrap: wrap;
      gap: 15px;

    @include g.media('sm') {
      justify-content: center;
      gap: g.auto_clamp(20, 1024, 30, 1440);
    }
    @include g.media('lg') {
      max-width: 1160px;
      margin-right: auto;
      margin-left: auto;
    }

    &__item {
      width: 100%;
      background: var(--color-white);
      border-radius: 20px;
      padding: g.vw(16) g.vw(24) g.vw(24) g.vw(24);

      @include g.media('sm') {
        width: calc( ( 100% - 30px ) / 2);
      }
      
      @include g.media('lg') {
        width: calc((100% - 60px) / 3);
        padding: 55px 40px 40px 40px;
      }

      @include g.media('xl') {
        width: 365px;
      }

      &__img {
        text-align: center;
        margin-bottom: 10px;
        
        & img {
          height: 50px;
        }
      }

      &__title {
        font-size: 17px;
        font-weight: 700;
        text-align: center;
        margin-bottom: g.vw(15);
        @include g.media('lg') {
          margin-bottom: 10px;
        }
      }
  
      &__text {
        font-size: g.auto_clamp(14, 375, 16, 1440);
        margin-right: g.vw(20);
        margin-left: g.vw(20);
        @include g.media('lg') {
          height: 3.5em;
          margin-right: 15px;
          margin-left: 15px;
        }
      }
      &:nth-of-type(2) &__text {
        text-align: center;
      }

      &__linkButton {
        margin-top: g.vw(20);
        @include g.media('xs') {
          margin-right: auto;
          margin-left: auto;
        }
        @include g.media('lg') {
          margin-top: 35px;
        }
        div {
          display: block;
          width: 100%;
        }
        a {
          display: block;
        }
      }
    }
  }
}