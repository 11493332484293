@use '@globals' as g;

.home-onayami {
  @media screen and (min-width: 768px) {
    background: url('/images/bg_02.png') no-repeat top center / 2000px;
    margin-top: 100px;
    padding-top: 200px;

    &__inner {
      position: relative;
      width: 1160px;
      margin-right: auto;
      margin-left: auto;
    }

    &__list {
      position: relative;
      display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        border-left: 21px solid var(--color-white);
        border-right: 0;
        margin: auto;
        position: absolute;
        right: -20px;
        top: 0;
        bottom: 0;
      }

      &__wrapper {
        background: var(--color-white);
        border-radius: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: hidden;
      }

      width: 650px;
      background: var(--color-white);
      padding: 70px 60px;

      li {
        background: url('/svg/icon_check.svg') no-repeat left center / 18px 16px;
        font-size: 1.5rem;
        font-weight: 500;
        padding-left: 35px;
        line-height: 2.4;
        + li {
          margin-top: 1em;
        }
      }

      &__text {
        width: 510px;
        color: var(--color-white);
        font-weight: 700;
        font-size: 1.5rem;
        background: var(--color-green);
        padding: 65px 10px 65px 100px;
        line-height: 3;
        span {
          position: relative;
          &::after {
            background: var(--color-white);  
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: -0.5em;
            left: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1399px) {
    background-size: 1400px;
    padding-top: g.vw(50);
    &__inner {
      width: 100%;
      padding-right: g.vw(10);
      padding-left: g.vw(10);
    }
    &__list {
      width: 50%;
      padding: g.vw(10);
      li {
        font-size: g.vw(5.5);
      }
      &__text {
        font-size: g.vw(6);
        width: 50%;
        padding: g.vw(10) g.vw(35);
        span {
          position: relative;
          &::after {
            background: var(--color-white);  
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: -0.5em;
            left: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    background: url('/images/bg_02_sp.png') no-repeat center top / 100%;
    padding-top: g.vw(75);

    &__inner {
      padding: 0 g.vw(14);
    }

    &__list {
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-right: g.vw(40) solid transparent;
        border-left: g.vw(40) solid transparent;
        border-top: g.vw(20) solid var(--color-white);
        border-bottom: 0;
        margin: auto;
        position: absolute;
        bottom: g.vw(-19);
        right: 0;
        left: 0;
      }

      &__wrapper {
        background: var(--color-white);
        border-radius: g.vw(20);
        overflow: hidden;
      }

      background: var(--color-white);
      padding: g.vw(20);

      li {
        background: url('/svg/icon_check.svg') no-repeat left top 9px / 14px 13px;
        font-size: g.auto_clamp(15, 375, 18, 720);
        padding-left: g.vw(23);

        + li {
          margin-top: .5em;
        }
      }
      &__text {
        color: var(--color-white);
        font-size: g.auto_clamp(15, 375, 18, 720);
        font-weight: 700;
        background: var(--color-green);
        padding: g.vw(25);
        line-height: 2;

        span {
          position: relative;
          text-decoration: underline;
        }

      }
    }
  }
}
.title02 {
  color: var(--color-white);
  font-weight: 700;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 26px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.6rem;
    margin-bottom: 25px;
  }
}